import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from './Modules/Components/Home';

import './Modules/Ressources/Stylesheets/App.scss';

require('dotenv').config()

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debug:0
    }
  }

  componentDidMount = () => {
    let cond = this.props.debug === 1;
    if(cond) console.log("You've entered Debug mode");
    this.setState({debug:cond});
  }


  render() {
    return (
      <Fragment>
        <Router>
          <Switch>
            {/* default route */}
            <Route exact path="/" render={(props) => <Home debug={this.state.debug} {...props} /> }/>
          </Switch>
        </Router>
      </Fragment>
    );
  }
}
