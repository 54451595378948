import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Ressources/Stylesheets/SRLPrompts.scss';

import { Button, FormGroup, Label } from 'reactstrap';
import LikerScale from './LikertScale';
import RadioButton from './RadioButton';

import quickpiInterface from '../Ressources/Images/interface-quickpi.PNG';

export default class SRLInitialPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nbQuestions: 7,
            question: 0,
            inAge: "",
            quickPi: "",
            experience: "",
            lieu: "",
            aide: "",
            raison: "",
            objectif: "",
            inAgeError: false,
            quickPiError: false,
            aideError: false,
            experienceError: false,
            lieuError: false,
            raisonError: false,
            objectifError: false,
            inAgeValues: ["J'ai 14 ans ou plus", "J'ai moins de 14 ans", "Je ne souhaite pas répondre"],
            quickPiValues: ["Jamais", "Une fois", "De nombreuses fois"],
            experienceValues: ["Jamais", "Une fois", "De nombreuses fois"],
            lieuValues: ["Je suis en classe", "Je suis à la maison"],
            aideValues: ["Je suis en autonomie totale", "Très rarement", "De temps en temps", "Fréquemment", "Très fréquemment"],
            raisonValues: ["L'activité est imposée", "L'activité est sugérée", "Je viens m'exercer de manière personnelle"],
            objectifValues: ["Je n'ai pas d'objectif", "Avoir le meilleur score", "Avoir une bonne note pour mon cours", "Je veux m'amuser", "Progresser en programmation", "Apprendre à programmer un objet électronique"],
        };
    }

    componentDidMount = () => {
    }

    cancel = () => {
        this.props.cancel();
    }

    /* ---------------------------------------------------------------------------------------------
       ---------------------------------------- Mutators -------------------------------------------
       --------------------------------------------------------------------------------------------- */
    inAgeUpdate = (label) => {
        this.setState({ inAge: label, inAgeError: false });
    }
    quickPiUpdate = (label) => {
        this.setState({ quickPi: label, quickPiError: false });
    }
    experienceUpdate = (label) => {
        this.setState({ experience: label, experienceError: false });
    }
    aideUpdate = (label) => {
        this.setState({ aide: label, aideError: false });
    }
    lieuUpdate = (label) => {
        this.setState({ lieu: label, lieuError: false });
    }
    raisonUpdate = (label) => {
        this.setState({ raison: label, raisonError: false });
    }
    objectifUpdate = (label) => {
        this.setState({ objectif: label, objectifError: false });
    }

    /* ---------------------------------------------------------------------------------------------
       ---------------------------------- Validation handlers --------------------------------------
       --------------------------------------------------------------------------------------------- */
    validate = () => {
        let obj = {};
        let next_question = this.state.question === 0 ? this.checkAge : this.scrollRight;
        let function_validate = () => this.setState({ question: this.state.question + 1 }, () => { next_question(); });

        if (this.state.question === 0) {
            this.checkQuestion(this.state.inAge,
                function_validate,
                () => this.setState({ inAgeError: true }));
        }
        else if (this.state.question === 1) {
            this.checkQuestion(this.state.quickPi,
                function_validate,
                () => this.setState({ quickPiError: true }));
        }
        else if (this.state.question === 2) {
            this.checkQuestion(this.state.experience,
                function_validate,
                () => this.setState({ experienceError: true }));
        }
        else if (this.state.question === 3) {
            this.checkQuestion(this.state.lieu,
                function_validate,
                () => this.setState({ lieuError: true }));
        }
        else if (this.state.question === 4) {
            this.checkQuestion(this.state.aide,
                function_validate,
                () => this.setState({ aideError: true }));
        }
        else if (this.state.question === 5) {
            this.checkQuestion(this.state.raison,
                function_validate,
                () => this.setState({ raisonError: true }));
        }
        else if (this.state.question === 6) {
            this.checkQuestion(this.state.objectif,
                () => {
                    obj = {};
                    obj["quickpi"] = this.state.quickPi;
                    obj["experience"] = this.state.experience;
                    obj["aide"] = this.state.aide;
                    obj["lieu"] = this.state.lieu;
                    obj["raison"] = this.state.raison;
                    obj["objectif"] = this.state.objectif;

                    sessionStorage.setItem("objectif_redraw", obj["objectif"] === "Je n'ai pas d'objectif");
                    sessionStorage.setItem("objectif_item", obj["objectif"]);
                    this.props.validate(obj);
                },
                () => this.setState({ objectifError: true })
            );
        }
    }

    checkQuestion = (item, func_validate, func_error) => {
        let cond = item === "";
        if (cond) {
            func_error();
        } else {
            func_validate();
        }
    }

    scrollRight = () => {
        let elements = document.getElementById("elements");
        let width = elements.scrollWidth * this.state.question / this.state.nbQuestions;
        elements.scrollTo({
            left: width,
            behavior: 'smooth'
        });
    }

    checkAge = () => {
        if (this.state.inAge === this.state.inAgeValues[0]) {
            this.scrollRight();
        }
        else {
            this.props.cancel();
        }
    }

    drawProgression = () => {
        let progressions = [];
        for (let i = 0; i < this.state.nbQuestions; i++) {
            progressions.push(
                <div key={i} style={{
                    width: `${(1 / (this.state.nbQuestions + 1) * 100)}%`,
                    backgroundColor: (i === this.state.question ? "rgb(251, 189, 20)" : (i > this.state.question ? "#E5E5E5" : "rgb(59, 251, 20)"))
                }}></div>
            )
        }
        return progressions;
    }


    render() {
        return (
            <div id={"prompt-srl"}>
                <fieldset className="subformulaire">
                    <div className={"cell"}>
                        <div className={"progression"}>
                            {this.drawProgression().map((item) => item)}
                        </div>
                    </div>
                    <div className={"cell"}>
                        <div id={"elements"}>
                            <FormGroup className={"item" + (this.state.inAgeError ? " error" : "")}>
                                <div className={"description"} style={{ marginBottom: "15px" }}>
                                    Avant de commencer, merci de préciser :
                            </div>
                                <Label>As-tu plus de 14 ans ?</Label>
                                {this.state.inAgeError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <RadioButton labels={this.state.inAgeValues} name={"lieu"} update={this.inAgeUpdate} value={this.state.inAge}></RadioButton>
                                <div className={"description"} style={{ marginTop: "15px", marginBottom: "0px" }}>
                                    La réponse n'aura aucun impact sur les exercices proposés.
                            </div>
                            </FormGroup>
                            <FormGroup className={"item" + (this.state.quickPiError ? " error" : "")}>
                                <div className={"description"}>
                                    Bienvenue sur Quick-pi. Pour nous aider à améliorer ce contenu, nous souhaitons te poser quelques questions.
                                </div>
                                <Label>As-tu déjà utilisé Quick-pi ?</Label>
                                <img src={quickpiInterface} alt={"interface quick-pi"}></img>
                                {this.state.quickPiError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale uuid={this.props.uuid} labels={this.state.quickPiValues} useSmiley={false} update={this.quickPiUpdate}></LikerScale>
                            </FormGroup>
                            <FormGroup className={"item" + (this.state.experienceError ? " error" : "")}>
                                <Label>As-tu déjà programmé ?</Label>
                                {this.state.experienceError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale uuid={this.props.uuid} labels={this.state.experienceValues} useSmiley={false} update={this.experienceUpdate}></LikerScale>
                            </FormGroup>
                            <FormGroup className={"item" + (this.state.lieuError ? " error" : "")}>
                                <Label>Où fais-tu cette activité ?</Label>
                                {this.state.lieuError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <RadioButton labels={this.state.lieuValues} name={"lieu"} update={this.lieuUpdate} value={this.state.lieu}></RadioButton>
                            </FormGroup>
                            <FormGroup className={"item" + (this.state.aideError ? " error" : "")}>
                                <Label>Pendant cette activité, penses-tu que tu pourras te faire aider souvent ?</Label>
                                {this.state.aideError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale labels={this.state.aideValues} useSmiley={false}
                                    update={this.aideUpdate} uuid={this.props.uuid}></LikerScale>
                            </FormGroup>
                            <FormGroup className={"item" + (this.state.raisonError ? " error" : "")}>
                                <Label>Pourquoi fais-tu cette activité ?</Label>
                                {this.state.raisonError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <RadioButton labels={this.state.raisonValues} name={"raison"} update={this.raisonUpdate} value={this.state.raison}></RadioButton>
                            </FormGroup>
                            <FormGroup className={"item" + (this.state.objectifError ? " error" : "")}>
                                <Label>Quels sont tes objectifs ?</Label>
                                {this.state.objectifError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <RadioButton labels={this.state.objectifValues} name={"objectif"} update={this.objectifUpdate} value={this.state.objectif}></RadioButton>
                            </FormGroup>
                            <div className={"margin-item-right"}></div>
                        </div>
                    </div>
                    <div className={"cell"}>
                        <Button id={"validate_prompt_srl"} onClick={() => this.validate()}>
                            {this.state.question === this.state.nbQuestions - 1 ? "Valider mes réponses" : "Valider"}
                        </Button>
                    </div>
                    <div className={"cell"}>
                        <Button id={"cancel_prompt_srl"} onClick={() => this.cancel()}>
                            Je ne souhaite plus répondre
                        </Button>
                    </div>
                </fieldset>
            </div>
        );
    }
}
