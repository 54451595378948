import React, { Component } from 'react';

import '../Ressources/Stylesheets/LikertScale.scss';

import { v4 as uuidv4 } from 'uuid';

import {
    EmojiAngryFill, EmojiFrownFill, EmojiLaughingFill, EmojiSmileFill, EmojiNeutralFill
} from 'react-bootstrap-icons';

export default class LikerScale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            likertScale: [
                <EmojiAngryFill />,
                <EmojiFrownFill />,
                <EmojiNeutralFill />,
                <EmojiSmileFill />,
                <EmojiLaughingFill />
            ],
            itemSelected: -1
        };
    }

    componentDidMount = () => {
        if (!this.props.useSmiley) {
            let likert = []
            
            for (let i = 0; i < this.props.labels.length; i++) {
                likert.push(<div className="point"></div>);
            }
            this.setState({
                likertScale: likert
            });
        }
    }

    changeSelection = (id, label) => {
        this.setState({ itemSelected: id }, () => {
            this.props.update(label);
        });
    }

    setHovering = (event, id) => {
        // forced to handle hover event with pure javascript since React function are not correctly handled, especially OnMouseLeave.
        if(event.target.classList.contains("likert-item")){
            if(id !== this.state.itemSelected){
                event.target.classList.add("hovered");
            }else{
                if(event.target.classList.contains("hovered")){
                    event.target.classList.remove("hovered");
                }
            }
            event.target.addEventListener("mouseleave", (e) => this.removeHovering(e));
        }
    }
    removeHovering = (event) => {
        event.target.classList.remove("hovered");
    }


    uuidGen = () => {
        return uuidv4() + this.props.uuid;
    }

    render() {
        return (
            <>
                <div className={`likert-container item-${this.props.labels.length}`}>
                    {this.state.likertScale.map((item, i) => {
                        let isSelected = this.state.itemSelected === i ? "selected" : "";
                        return (
                            <div className={`likert-item ${isSelected}`} key={this.uuidGen()} 
                                    onClick={() => this.changeSelection(i, this.props.labels[i])} 
                                    onMouseOver={(e) => this.setHovering(e, i)}>
                                <div className="likert-smiley">
                                    {item}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={`likert-container no-before item-${this.props.labels.length}`}>
                    {this.props.labels.map((item, i) => {
                        let isSelected = this.state.itemSelected === i ? "selected" : "";
                        return (
                            <div className={`likert-item ${isSelected}`} key={this.uuidGen()} 
                                    onClick={() => this.changeSelection(i, item)}
                                    onMouseOver={(e) => this.setHovering(e, i)}>
                                <div className="likert-label">{item}</div>
                            </div>
                        );
                    })}
                </div>
        </>
        );
    }
}
