import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

let debug = 0;
const params = new URLSearchParams(window.location.search);
try{
  if(params.has("debug")){
    debug = parseInt(params.get("debug"));
  }
}catch(e){

}


ReactDOM.render(
  <App debug={debug} />,
  document.getElementById('root')
);

reportWebVitals();
