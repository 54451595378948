import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Ressources/Stylesheets/SRLPrompts.scss';

import { Button, FormGroup, Label } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import LikerScale from './LikertScale';
import RadioButton from './RadioButton';

export default class SRLPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nbQuestions: 3,
            question: 0,
            motivation: "",
            temps: "",
            objectif: "",
            motivationError: false,
            tempsError: false,
            objectifError: false,
            previous_objectif: "",
            motivationValues: ["Pas du tout", "Pas trop", "Moyennement", "Plutôt oui", "Oui beaucoup"],
            tempsValues: ["Très mal", "Mal", "Ni bien, ni mal", "Bien", "Très bien"],
            objectifValues1: ["Je n'ai pas d'objectif", "Avoir le meilleur score", "Avoir une bonne note pour mon cours", "Je veux m'amuser", "Progresser en programmation",
                "Apprendre à programmer un objet électronique"],
            objectifValues2: ["Pas du tout", "Pas trop", "Moyennement", "Plutôt oui", "Oui beaucoup"]
        };
    }

    componentDidMount = () => {
        let objectif_none = sessionStorage.getItem("objectif_redraw");
        if (objectif_none === undefined || objectif_none === null || objectif_none === "") {
            //request needed
        } else {
            this.setState({
                previous_objectif: sessionStorage.getItem("objectif_item")
            })
        }
    }

    cancel = () => {
        this.props.cancel();
    }

    objectif_draw = () => {
        let objectif_none = sessionStorage.getItem("objectif_redraw");
        if (objectif_none === 'true') {
            return (
                <FormGroup className={"item " + (this.state.objectifError ? " error" : "")}>
                    <Label >Quels sont tes objectifs ?</Label>
                    {this.state.objectifError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                    <RadioButton labels={this.state.objectifValues1} name={"objectif"} update={this.objectifUpdate} value={this.state.objectif}></RadioButton>
                </FormGroup>
            );
        } else {
            return (
                <FormGroup className={"item " + (this.state.objectifError ? " error" : "")}>
                    <Label >Penses-tu réussir à atteindre tes objectifs ?</Label>
                    {this.state.objectifError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                    <div className={"objectives"}>
                        <p className={"title"}>Ton Objectif :</p>
                        <p className={"items"} key={this.props.uuid + uuidv4()}>{this.state.previous_objectif}</p>
                    </div>
                    <LikerScale uuid={this.props.uuid} labels={this.state.objectifValues2} useSmiley={true} update={this.objectifUpdate}></LikerScale>
                </FormGroup>
            );
        }
    }

    /* ---------------------------------------------------------------------------------------------
       ---------------------------------------- Mutators -------------------------------------------
       --------------------------------------------------------------------------------------------- */
    motivationUpdate = (label) => {
        this.setState({ motivation: label, motivationError: false });
    }
    tempsUpdate = (label) => {
        this.setState({ temps: label, tempsError: false });
    }
    objectifUpdate = (label) => {
        this.setState({ objectif: label, objectifError: false });
    }


    /* ---------------------------------------------------------------------------------------------
       ---------------------------------- Validation handlers --------------------------------------
       --------------------------------------------------------------------------------------------- */

    validate = () => {
        let obj = {};
        let function_validate = () => this.setState({ question: this.state.question + 1 }, () => { this.scrollRight(); });
        if (this.state.question === 0) {
            this.checkQuestion(this.state.motivation,
                function_validate,
                () => this.setState({ motivationError: true }));
        }
        else if (this.state.question === 1) {
            this.checkQuestion(this.state.temps,
                function_validate,
                () => this.setState({ tempsError: true }));
        }
        else if (this.state.question === 2) {
            let objectif_none = sessionStorage.getItem("objectif_redraw");

            this.checkQuestion(this.state.objectif,
                () => {
                    if (objectif_none === 'true') {
                        obj["new_objectif"] = this.state.objectif;
                        sessionStorage.setItem("objectif_redraw", obj["new_objectif"] === "Je n'ai pas d'objectif");
                        sessionStorage.setItem("objectif_item", obj["new_objectif"]);
                    }else{
                        obj["objectif"] = this.state.objectif;
                    }
                    obj["motivation"] = this.state.motivation;
                    obj["temps"] = this.state.temps;
                    this.props.validate(obj);
                },
                () => this.setState({ objectifError: true }));
        }
    }

    checkQuestion = (item, func_validate, func_error) => {
        let cond = item === "";
        if (cond) {
            func_error();
        } else {
            func_validate();
        }
    }

    scrollRight = () => {
        let elements = document.getElementById("elements");
        let width = elements.scrollWidth * this.state.question / this.state.nbQuestions;
        elements.scrollTo({
            left: width,
            behavior: 'smooth'
        });
    }

    drawProgression = () => {
        let progressions = [];
        for (let i = 0; i < this.state.nbQuestions; i++) {
            progressions.push(
                <div key={i} style={{
                    width: `${(1 / (this.state.nbQuestions + 1) * 100)}%`,
                    backgroundColor: (i === this.state.question ? "rgb(251, 189, 20)" : (i > this.state.question ? "#E5E5E5" : "rgb(59, 251, 20)"))
                }}></div>
            )
        }
        return progressions;
    }

    render() {
        return (
            <div id={"prompt-srl"}>
                <fieldset className="subformulaire">
                    <div className={"cell"}>
                        <div className={"progression"}>
                            {this.drawProgression().map((item) => item)}
                        </div>
                    </div>
                    <div className={"cell"}>
                        <div id={"elements"}>
                            <FormGroup className={"item " + (this.state.motivationError ? " error" : "")}>
                                <Label >Es-tu motivé(e) par cette activité ?</Label >
                                {this.state.motivationError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale uuid={this.props.uuid} labels={this.state.motivationValues} useSmiley={true} update={this.motivationUpdate}></LikerScale>
                            </FormGroup>
                            <FormGroup className={"item " + (this.state.tempsError ? " error" : "")}>
                                <Label >Comment gères-tu ton temps ?</Label >
                                {this.state.tempsError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale labels={this.state.tempsValues} useSmiley={true} update={this.tempsUpdate}></LikerScale>
                            </FormGroup>

                            {this.objectif_draw()}

                            <div className={"margin-item-right"}></div>
                        </div>
                    </div>
                    <div className={"cell"}>
                        <Button id={"validate_prompt_srl"} onClick={() => this.validate()}>
                            {this.state.question === this.state.nbQuestions - 1 ? "Valider mes réponses" : "Valider"}
                        </Button>
                    </div>
                    <div className={"cell"}>
                        <Button id={"cancel_prompt_srl"} onClick={() => this.cancel()}>
                            Je ne souhaite plus répondre
                        </Button>
                    </div>
                </fieldset>
            </div>
        );
    }
}
