import React, { Component } from 'react';

import { FormGroup, Label, Input } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import '../Ressources/Stylesheets/RadioButton.scss';

export default class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
    }


    render() {
        return (
            this.props.labels.map((item, i) => {
                return(
                    
                    <FormGroup check key={uuidv4()}>
                        
                        <Label check className={this.props.value === item ? "checked" : ""}>
                        <Input type="radio" name={`${this.props.name}`} checked={this.props.value === item} 
                                onChange={() => this.props.update(item)} />{' '}
                            {item}</Label>
                    </FormGroup>
                    
                );
            })
        );
    }
}
