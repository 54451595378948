import React, { Component } from 'react';

import axios from "axios";

import { v4 as uuidv4 } from 'uuid';

import SRLPrompt from "./SRLPrompt"
import SRLInitialPrompt from "./SRLInitialPrompt"
import SRLFinalPrompt from "./SRLFinalPrompt"

import '../Ressources/Stylesheets/Home.scss';

import JsChannel from "./JsChannel";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.timer = 0;
        this.state = {
            // -------------------------------- JS CHANNEL ------------------------------- //
            chan_parent: 0,
            // ------------------------------- DATA STORAGE ------------------------------ //
            event_queue: [],
            sent_event_queue: [],
            current_navigation:"",
            // --------------------- REQUEST MANAGEMENT WITH BACKEND --------------------- //

            isSending: false, // if the module already try to send data.
            // ----------- On request having errors :
            maximumSendingTries: 5, // maximum repeating number of request resent
            currentTriesNumber: 0, // current repeating number of request resent
            // ----------- Congestion : Size
            maxQueueCongestion: 9,
            // ----------- Congestion : Time
            beginningTimerCongestion: 0,
            currentTimerCongestion: 0,
            updateTimer: 1000 * 30,
            maxTimeCongestion: 1000 * 60 * 2,

            // -------------------------- SRL PROMPT (Initial) --------------------------- //
            // ----------- Validation :
            isInitialPromptValidated: false,
            isInitialPromptDrawed: false,
            promptInitialSRLValues: {},
            onInitialPromptValidated: () => { },
            onInitialPromptCanceled: () => { },

            // --------------------------- SRL PROMPT (middle) --------------------------- //
            // ----------- Validation :
            isPromptValidated: false,
            isPromptDrawed: false,
            promptSRLValues: {},
            onPromptValidated: () => { },
            onPromptCanceled: () => { },

            // --------------------------- SRL PROMPT (final) ---------------------------- //
            // ----------- Validation :
            isFinalPromptValidated: false,
            isFinalPromptDrawed: false,
            promptFinalSRLValues: {},
            onFinalPromptValidated: () => { },
            onFinalPromptCanceled: () => { }
        };
    }

    componentDidMount = () => {
        this.setState({
            // -------------------------------------------- JS CHANNEL CREATION -------------------------------------------- //
            chan_parent: JsChannel().build({ window: window.parent, origin: "*", scope: `${process.env.REACT_APP_JSCHANNEL_SCOPE}` })
        }, () => {
            // -------------------------------------------- JS CHANNEL METHODS --------------------------------------------- //
            // ------------------------ Register Object
            this.state.chan_parent.bind(`${process.env.REACT_APP_JSCHANNEL_FUNCTION_ON_ACTION_REGISTERING}`, (_, params) => {
                // mouse handling and reducing
                if (params.reference === "souris") {
                    this.ProcessMouseEvent(params);
                }
                else {
                    if(params.reference === "navigation"){
                        this.ProcessNavigationEvent(params);
                    }
                    else{
                        this.RegisterLog(params);
                    }
                }
            });

            // ------------------------ PROMPT SRL (Initial)
            this.state.chan_parent.bind(`${process.env.REACT_APP_JSCHANNEL_FUNCTION_ON_BEFORE_ACTIVITY_BEGINS}`, (trans, params) => {
                trans.delayReturn(true);
                this.setState({
                    isInitialPromptDrawed: true,
                    onInitialPromptValidated: (values) => {
                        params.onvalidated(values);
                        trans.complete();
                    },
                    onInitialPromptCanceled:() => {
                        params.oncanceled();
                        trans.complete();
                        this.setState({
                            isInitialPromptDrawed:false
                        });
                    }
                });
            });

            // ------------------------ PROMPT SRL (middle)
            // Validation method
            this.state.chan_parent.bind(`${process.env.REACT_APP_JSCHANNEL_FUNCTION_ON_AFTER_ACTIVITY_BEGINS}`, (trans, params) => {
                trans.delayReturn(true);
                this.setState({
                    isPromptDrawed: true,
                    onPromptValidated: (values) => {
                        params.onvalidated(values);
                    },
                    onPromptCanceled:() => {
                        params.oncanceled();
                        trans.complete();
                        this.setState({
                            isPromptDrawed:false
                        });
                    }
                });
            });

            // ------------------------ PROMPT SRL (final)
            this.state.chan_parent.bind(`${process.env.REACT_APP_JSCHANNEL_FUNCTION_ON_ACTIVITY_ENDS}`, (trans, params) => {
                trans.delayReturn(true);
                this.setState({
                    isFinalPromptDrawed: true,
                    onFinalPromptValidated: (values) => {
                        params.onvalidated(values);
                    },
                    onFinalPromptCanceled:() => {
                        params.oncanceled();
                        trans.complete();
                        this.setState({
                            isFinalPromptDrawed:false
                        });
                    }
                });
            });

            // ------------------------ MODULE Unload
            this.state.chan_parent.bind(`${process.env.REACT_APP_JSCHANNEL_FUNCTION_ON_MODULE_UNLOAD}`, (trans, _) => {
                trans.delayReturn(true);
                this.sendRequest(() => trans.complete());
            });
        });
    }

    // --------------------------------------------- MOUSE EVENT -------------------------------------------- //
    // function which pre-process mouse-events
    ProcessMouseEvent = (obj) => {
        let queue = [...this.state.event_queue];
        let q_obj = queue[queue.length-1];
        if (q_obj && q_obj.reference === "souris") {
            if (q_obj.zone === obj.zone) {
                let q_x = q_obj["coordonnees_page_x"];
                let q_y = q_obj["coordonnees_page_y"];
                let x = obj["coordonnees_page_x"];
                let y = obj["coordonnees_page_y"];
                if ((x - q_x) * (x - q_x) + (y - q_y) * (y - q_y) > 49 || q_obj.etat !== obj.etat) {
                    this.RegisterLog(obj);
                }
            }
            else {
                // new zone, then we register it
                this.RegisterLog(obj);
            }
        }
        else {
            this.RegisterLog(obj);
        }
    }

    // --------------------------------------------- Navigation -------------------------------------------- //
    ProcessNavigationEvent = (obj) => {
        if( this.state.current_navigation === ""){
            this.setState({
                current_navigation:`${obj.module},${obj.id_sujet},${obj.version}`
            }, () => this.RegisterLog(obj));
        }
        else{
            // avoid double identical rows to be inserted
            if(`${obj.module},${obj.id_sujet},${obj.version}` !== this.state.current_navigation) {
                this.setState({
                    current_navigation:`${obj.module},${obj.id_sujet},${obj.version}`
                }, () => this.RegisterLog(obj));
            }
        }
    }

    // -------------------------------------------- SENDING DATA -------------------------------------------- //

    // function which stores the data in the queue and transmit the request
    RegisterLog = (obj) => {
        let queue = [...this.state.event_queue];
        queue.push(obj);
        this.setState({
            event_queue: queue
        }, () => {
            if(this.props.debug) console.log(this.state.event_queue);
            // Congestion Size
            if (this.state.event_queue.length > this.state.maxQueueCongestion) {
                if (!this.state.isSending) {
                    this.sendRequest();
                }
            } else {
                // Congestion Time
                this.startTimer();
            }
        });
    }

    // Method which send a request to the server in order to store logs
    sendRequest = (f = null) => {
        // setting request to exist
        this.setState({
            isSending: true
        }, () => {
            // transfer to a storage queue
            this.setState({
                sent_event_queue: [...this.state.event_queue],
                event_queue: [],
            }, () => {
                this.StoreData(this.state.sent_event_queue, f);
            })
        });
    }

    // Method which re-send a request to the server in order to store logs
    resendRequest = () => {
        if (this.state.currentTriesNumber >= this.state.maximumSendingTries) {
            //data lost
            this.setState({
                currentTriesNumber: 0,
                sent_event_queue: [],
                isSending: false
            });
        } else {
            this.setState({
                currentTriesNumber: this.state.currentTriesNumber + 1,
                sent_event_queue: [...this.state.sent_event_queue].concat([...this.state.event_queue]),
                event_queue: []
            }, () => {
                this.StoreData(this.state.sent_event_queue);
            });
        }
    }

    // Method which handle the API call to store the logs
    StoreData = (data, f = null) => {
        // if too much currentTriesNumber 
        axios.post(`${process.env.REACT_APP_BACK_END_URL}${process.env.REACT_APP_BACK_END_URL_STORE_DATA}`, {
            data: data
        })
            .then(request => {
                if (request.status === 200) {
                    // server responds with a known code
                    // problems with some data but other are inserted
                    if (request.data.code === 3) {
                        if(this.props.debug) console.log(request.data);
                    }
                    this.setState({
                        sent_event_queue: [],
                        isSending: false
                    }, () => {
                        if(f !== null){
                            f();
                        }
                    });
                } else {
                    // server responds with a unknown code
                    if(this.props.debug) console.log("Wrong status : Not Handled " + request.status);
                    setTimeout(() => {
                        this.resendRequest();
                    }, 1 * 30 * 1000);
                }
            })
            .catch(error => {
                if (error.status === 400) {
                    // server responds with an known error
                    if(this.props.debug) console.log(error.data);
                    setTimeout(() => {
                        this.resendRequest();
                    }, 1 * 30 * 1000);
                } else {
                    // server responds with an unknown error
                    setTimeout(() => {
                        this.resendRequest();
                    }, 1 * 60 * 1000);
                }
            });
    }

    // ------------------------------------------ Congestion Time  ------------------------------------------ //
    // Method which start the timer for time congestion handling
    startTimer = (f) => {
        this.setState({
            beginningTimerCongestion: Date.now(),
            currentTimerCongestion: Date.now(),
        }, () => {
            this.timer = setInterval(this.countDown, this.state.updateTimer);
        });
    }

    // Method which handle the maximum time to send remaining data (logs) to the server
    countDown = () => {
        this.setState({
            currentTimerCongestion: Date.now()
        }, () => {
            if (this.state.currentTimerCongestion - this.state.beginningTimerCongestion > this.state.maxTimeCongestion) {
                clearInterval(this.timer);
                if (!this.state.isSending) {
                    this.sendRequest();
                } else {
                    this.startTimer();
                }
            }
        });
    }


    // ---------------------------------------- SRL PROMPT (initial) ---------------------------------------- //
    // ------------------------ Validation :

    // Method which handle the validation of the initial srl prompt form
    ValidatePromptInitialSRL = (values) => {
        if (!this.state.isInitialPromptValidated) {
            this.setState({
                isInitialPromptValidated: true
            }, () => {
                this.state.onInitialPromptValidated(values);
                this.setState({
                    isInitialPromptDrawed: false,
                    isInitialPromptValidated: false
                });
            });
        }
    }

    // ---------------------------------------- SRL PROMPT (middle) ----------------------------------------- //
    // ------------------------ Validation :
    // Method which handle the validation of the srl prompt form 
    ValidatePromptSRL = (values) => {
        if (!this.state.isPromptValidated) {
            this.setState({
                isPromptValidated: true
            }, () => {
                this.state.onPromptValidated(values);
                this.setState({
                    isPromptDrawed: false,
                    isPromptValidated: false
                });
            });
        }
    }

    // ----------------------------------------- SRL PROMPT (final) ----------------------------------------- //
    // ------------------------ Validation :

    // Method which handle the validation of the final srl prompt form
    ValidatePromptFinalSRL = (values) => {
        if (!this.state.isFinalPromptValidated) {
            this.setState({
                isFinalPromptValidated: true
            }, () => {
                this.state.onFinalPromptValidated(values);
                this.setState({
                    isFinalPromptDrawed: false,
                    isFinalPromptValidated: false
                });
            });
        }
        this.setState({
            isFinalPromptValidated: true,
            promptFinalSRLValues: values
        });
    }

    // ------------------------------------------------ RENDER ---------------------------------------------- //
    render() {
        return (
            <div id="HomeContainer">
                {this.state.isPromptDrawed ?
                    <SRLPrompt uuid={uuidv4()} validate={this.ValidatePromptSRL} cancel={this.state.onPromptCanceled}></SRLPrompt>
                    :
                    this.state.isInitialPromptDrawed ?
                        <SRLInitialPrompt uuid={uuidv4()} validate={this.ValidatePromptInitialSRL} cancel={this.state.onInitialPromptCanceled}></SRLInitialPrompt>
                        :
                        this.state.isFinalPromptDrawed ?
                            <SRLFinalPrompt uuid={uuidv4()} validate={this.ValidatePromptFinalSRL} cancel={this.state.onFinalPromptCanceled}></SRLFinalPrompt>
                            :
                            <></>
                }
            </div>
        );
    }
}
