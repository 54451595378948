import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Ressources/Stylesheets/SRLPrompts.scss';

import { Button, FormGroup, Label, Input } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import LikerScale from './LikertScale';

export default class SRLFinalPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nbQuestions: 4,
            question: 0,
            commentaire: "",
            strategie: "",
            temps: "",
            objectif: "",
            strategieError: false,
            tempsError: false,
            objectifError: false,
            previous_objectif: "",
            tempsValues: ["Très mal", "Mal", "Ni bien, ni mal", "Bien", "Très bien"],
            strategieValues: ["Très mauvaise", "Mauvaise", "Ni bonne, ni mauvaise", "Bonne", "Très bonne"],
            objectifValues: ["Pas du tout", "Pas trop", "Moyennement", "Plutôt oui", "Oui beaucoup"]
        };
    }

    componentDidMount = () => {
        let objectif_none = sessionStorage.getItem("objectif_redraw");
        if (objectif_none === undefined || objectif_none === null || objectif_none === "") {
            //request needed
        } else {
            if (objectif_none === 'true') {
                this.setState({
                    objectif: "Pas du tout d’accord",
                    nbQuestions: 3
                });
            } else {
                this.setState({
                    previous_objectif: sessionStorage.getItem("objectif_item")
                });
            }
        }
    }

    cancel = () => {
        this.props.cancel();
    }

    objectif_draw = () => {
        let objectif_none = sessionStorage.getItem("objectif_redraw");
        if (objectif_none === 'true') {
            return (<></>);
        } else {
            return (
                <FormGroup className={"item " + (this.state.objectifError ? " error" : "")}>
                    <Label>As-tu atteint tes objectifs ?</Label>
                    <div className={"objectives"}>
                        <p className={"title"}>Ton Objectif :</p>
                        <p className={"items"} key={this.props.uuid + uuidv4()}>{this.state.previous_objectif}</p>
                    </div>
                    {this.state.objectifError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                    <LikerScale uuid={this.props.uuid} labels={this.state.objectifValues} useSmiley={true} update={this.objectifUpdate}></LikerScale>
                </FormGroup>
            );
        }
    }

    /* ---------------------------------------------------------------------------------------------
       ---------------------------------------- Mutators -------------------------------------------
       --------------------------------------------------------------------------------------------- */

    strategieUpdate = (label) => {
        this.setState({ strategie: label, strategieError: false });
    }
    tempsUpdate = (label) => {
        this.setState({ temps: label, tempsError: false });
    }
    objectifUpdate = (label) => {
        this.setState({ objectif: label, objectifError: false });
    }

    commentaireUpdate = (label) => {
        this.setState({ commentaire: label });
    }


    /* ---------------------------------------------------------------------------------------------
       ---------------------------------- Validation handlers --------------------------------------
       --------------------------------------------------------------------------------------------- */

    validate = () => {
        let obj = {};
        let function_validate = () => this.setState({ question: this.state.question + 1 }, () => { this.scrollRight(); });
        let validation = () => {
            obj["commentaire"] = this.state.commentaire;
            obj["objectif"] = this.state.objectif;
            obj["temps"] = this.state.temps;
            obj["strategie"] = this.state.strategie;
            this.props.validate(obj);
        }
        if (this.state.question === 0) {
            this.checkQuestion(this.state.strategie,
                function_validate,
                () => this.setState({ strategieError: true }));
        }
        else if (this.state.question === 1) {
            this.checkQuestion(this.state.temps,
                function_validate,
                () => this.setState({ tempsError: true }));
        }
        else if (this.state.question === 2) {
            if (this.state.nbQuestions - 1 === this.state.question) {
                validation();
            }
            else {
                this.checkQuestion(this.state.objectif,
                    function_validate,
                    () => this.setState({ objectifError: true }));
            }
        }
        else if (this.state.question === 3) {
            validation();
        }
    }

    checkQuestion = (item, func_validate, func_error) => {
        let cond = item === "";
        if (cond) {
            func_error();
        } else {
            func_validate();
        }
    }

    scrollRight = () => {
        let elements = document.getElementById("elements");
        let width = elements.scrollWidth * this.state.question / this.state.nbQuestions;
        elements.scrollTo({
            left: width,
            behavior: 'smooth'
        });
    }

    drawProgression = () => {
        let progressions = [];
        for (let i = 0; i < this.state.nbQuestions; i++) {
            progressions.push(
                <div key={i} style={{
                    width: `${(1 / (this.state.nbQuestions + 1) * 100)}%`,
                    backgroundColor: (i === this.state.question ? "rgb(251, 189, 20)" : (i > this.state.question ? "#E5E5E5" : "rgb(59, 251, 20)"))
                }}></div>
            )
        }
        return progressions;
    }

    render() {
        return (
            <div id={"prompt-srl"}>
                <fieldset className="subformulaire">
                    <div className={"cell"}>
                        <div className={"progression"}>
                            {this.drawProgression().map((item) => item)}
                        </div>
                    </div>
                    <div className={"cell"}>
                        <div id={"elements"}>
                            <FormGroup className={"item " + (this.state.strategieError ? " error" : "")}>
                                <Label>Que penses-tu de ta stratégie pour atteindre ton objectif ?</Label>
                                {this.state.strategieError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale uuid={this.props.uuid} labels={this.state.strategieValues} useSmiley={true} update={this.strategieUpdate}></LikerScale>
                            </FormGroup>
                            <FormGroup className={"item " + (this.state.tempsError ? " error" : "")}>
                                <Label>Comment as-tu géré ton temps pour atteindre ton objectif ?</Label>
                                {this.state.tempsError ? <div className={"objectives"}><p className={"title"} style={{ color: 'red' }}>Veuillez remplir le champ</p></div> : <></>}
                                <LikerScale labels={this.state.tempsValues} useSmiley={true} update={this.tempsUpdate}></LikerScale>
                            </FormGroup>

                            {this.objectif_draw()}

                            <FormGroup className={"item"}>
                                <Label>Est-ce que tu as quelques chose à ajouter ?</Label>
                                <Input type="textarea" id="commentaire" onChange={(e) => this.commentaireUpdate(`${e.target.value}`)} />
                            </FormGroup>

                            <div className={"margin-item-right"}></div>
                        </div>
                    </div>
                    <div className={"cell"}>
                        <Button id={"validate_prompt_srl"} onClick={() => this.validate()}>
                            {this.state.question === this.state.nbQuestions - 1 ? "Valider mes réponses" : "Valider"}
                        </Button>
                    </div>
                    <div className={"cell"}>
                        <Button id={"cancel_prompt_srl"} onClick={() => this.cancel()}>
                            Je ne souhaite plus répondre
                        </Button>
                    </div>
                </fieldset>
            </div>
        );
    }
}
